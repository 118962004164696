import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  
    <Layout pageClass='error-page'>
    <SEO title="Thank you" />
        <main className='main'>
            <div className='typography inner'>
                <h1>THANK YOU</h1>
                <p>Thank you for your order. We will be in touch with you shortly.</p>
            </div>
        </main> 
    </Layout>
)

export default NotFoundPage
